import React, { useEffect, useState } from "react";
import { useRouter } from "next/router";
import { FlexContainer } from "@components/ui/flex_container";
import GetTranslatedStrings from "@helpers/GetTranslatedStrings";
import styles from "./HorizontalAd.module.scss";
import { fetcherPublic1819 } from "@utils/fetcherPublic1819";

export default function HorizontalAd({ adPosition, className, style }) {
    if (!adPosition) {
        console.error("No ad position provided");
        return null;
    }
    const t = GetTranslatedStrings();
    const router = useRouter();
    const [ad, setAd] = useState(null);

    const fetchAds = async () => {
        let adUrl = `1819/auglysing?placement=${adPosition}`;
        if (router.query.q) {
            adUrl += `&q=${router.query.q}`;
        }

        try {
            setAd(await fetcherPublic1819(adUrl));
        } catch (e) {
            console.error("Error fetching ad", e);
        }
    };

    useEffect(() => {
        fetchAds();
    }, [router.query.q]);

    console.log(ad);

    return (
        <div style={{ display: "flex", ...style }}>
            <FlexContainer
                column
                className={className}
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                }}
            >
                <a href={ad?.linkto} target="_blank" rel="noopener noreferrer">
                    <picture style={{ aspectRatio: 1018 / 128 }}>
                        <img src={ad?.img_url} className={styles.container} alt={t.ad} />
                    </picture>
                </a>
                {ad?.url && (
                    <div
                        style={{
                            color: "gray",
                            fontSize: "0.75em",
                            marginTop: "0.5em",
                        }}
                    >
                        {t.ad}
                    </div>
                )}
            </FlexContainer>
        </div>
    );
}
